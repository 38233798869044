export default function generatePassword() {
  const charactersArray = 'a-z,A-Z,0-9,#'.split(',');
  let CharacterSet = '';
  let password = '';
  const size = 10;

  if (charactersArray.indexOf('a-z') >= 0) {
    CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (charactersArray.indexOf('A-Z') >= 0) {
    CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  if (charactersArray.indexOf('0-9') >= 0) {
    CharacterSet += '0123456789';
  }
  if (charactersArray.indexOf('#') >= 0) {
    CharacterSet += '![]{}()%&*$#^<>~@|';
  }
  for (let i = 0; i < size; i += 1) {
    password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
  }
  return password;
}
