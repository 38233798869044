<template>
  <div class="flex-form">
    <el-form
      ref="userAdminForm"
      v-loading="loading"
      :model="userAdminForm"
      label-width="160px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Admin User
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Admin User
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ userUid }}
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Status"
        prop="status"
      >
        <el-select
          v-model="userAdminForm.status"
          placeholder="User status"
        >
          <el-option
            v-for="status in statuses"
            :key="status"
            :label="status"
            :value="status"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Username"
        prop="username"
      >
        <el-input v-model="userAdminForm.username" />
      </el-form-item>

      <el-form-item
        label="Role"
        prop="role"
      >
        <el-select
          v-model="userAdminForm.role"
          placeholder="User role"
        >
          <el-option
            v-for="role in roles"
            :key="role"
            :label="role"
            :value="role"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="!isEditMode"
        label="Password"
        prop="password"
        class="relative-wrapper"
      >
        <el-input
          v-model="userAdminForm.password"
          placeholder="At least 6 digit long"
          show-password
        />
        <password
          v-model="userAdminForm.password"
          placeholder="At least 6 digit long"
          :strength-meter-only="true"
          @score="setScore"
        />
        <el-button
          size="mini"
          @click="generatePassword"
        >
          Generate
        </el-button>
      </el-form-item>

      <el-form-item
        v-if="!isEditMode"
        label="Generated Password"
        prop="generatedPassword"
        class="relative-wrapper"
      >
        <el-input
          v-model="generatedPassword"
          placeholder="At least 6 digit long"
        />
        <el-button
          :disabled="!generatedPassword"
          size="mini"
          @click="useGeneratedPassword"
        >
          Use
        </el-button>
      </el-form-item>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          :disabled="!isEditMode && (score < 2 ||
            userAdminForm.username === '' ||
            userAdminForm.role === '' ||
            userAdminForm.password === '')"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getAdminUser,
  updateAdminUser,
  createAdminUser,
  getRoles,
  getStatuses,
} from '@/api/adminUser';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import Password from 'vue-password-strength-meter';
import generatePassword from '@/utils/password';

const addFormFields = {
  username: '',
  role: '',
  password: '',
};

const editFormFields = {
  username: '',
  status: '',
  role: '',
};

export default {
  name: 'AdminUserDetails',
  components: { Password },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    userUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      generatedPassword: null,
      loading: false,
      saving: false,
      userAdminForm: this.isEditMode ? editFormFields : addFormFields,
      user: {},
      score: 0,
      rules: {
        username: [{
            required: true,
            trigger: 'blur',
            message: 'Username is required',
          }],
        role: [{
            required: true,
            trigger: 'blur',
            message: 'Role is required',
          }],
        status: [{
            required: true,
            trigger: 'blur',
            message: 'Status is required',
          }],
        password: [{
            required: true,
            trigger: 'blur',
            message: 'Password is required',
          },
          {
            min: 6,
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.score < 3) {
                callback(new Error('Password not strong enough'));
              }
            },
            trigger: 'blur',
            message: 'Password not strong enough',
          }],
      },
    };
  },
  computed: {
    statuses() {
      return getStatuses();
    },
    roles() {
      return getRoles();
    },
  },
  watch: {
    userUid(val) {
      if (val) {
        this.getAdminUser(val);
      } else {
        this.resetForm();
      }
    },
  },
  created() {
    if (this.isEditMode) {
      this.getAdminUser(this.userUid);
    }
  },
  methods: {
    async getAdminUser(userUid) {
      try {
        this.loading = true;

        const data = await getAdminUser({ userUid });

        this.user = cloneDeep(data.user);
        this.userAdminForm = data.user;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    setScore(score) {
      this.score = score;
    },
    generatePassword() {
      this.generatedPassword = generatePassword();
    },
    useGeneratedPassword() {
      this.userAdminForm.password = this.generatedPassword;
    },
    async onEditSubmit() {
      this.$refs.userAdminForm.validate(async (valid) => {
        if (valid) {
          const updatedUser = makeUpdateObj(this.user, this.userAdminForm);
          if (Object.keys(updatedUser).length > 0) {
            try {
              this.saving = true;
              updatedUser.userUid = this.userUid;

              await updateAdminUser(updatedUser);
              await this.getAdminUser(this.userUid);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      try {
        this.saving = true;

        await createAdminUser(this.userAdminForm);

        this.$message({
          type: 'success',
          message: 'Admin user has been added successfully',
        });

        this.resetForm();
        this.saving = false;

        this.$emit('update');
        this.close();
      } catch (e) {
        this.saving = false;
      }
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.userAdminForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style scoped>
.relative-wrapper {
  position: relative;
}

.inline {
  position: absolute;
  right: -113px;
}

.inline.small {
  right: -79px;
}

.Password {
  max-width: 100%;
}
</style>
